import React from 'react';
import * as callApiUtil from '../utils/callApiUtil';
import NotificationBar from '../components/Notification';
import * as Validation from '../utils/validation';

class TopBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      offset: null,
      showAdArea: false,
      hasNotification:
        Validation.topBannerValidation(window.location.pathname, window.channelId) &&
        Validation.isMobileDevice() &&
        window.channelId !== 'SUPERAPP',
    };
  }
  componentDidMount() {
    this.getTopBanner();
  }

  // 偵測retina解析度
  notibarLoad = () => {
    let notibar = document.getElementsByClassName('NotificationBar')[0];
    const $header = document.getElementsByTagName('header')[0];
    let navAnchor = Array.from(document.getElementsByClassName('fui-nav-anchor')).length;
    let navTab = Array.from(document.getElementsByClassName('fui-nav-tab')).length;
    if (
      notibar &&
      (window.location.pathname === '/' || window.location.pathname.indexOf('/index')) &&
      !navAnchor.length &&
      !navTab.length
    ) {
      // notibarPadding()
      window.addEventListener('mousewheel', (e) => {
        if (!$header) return;
        let scrollY = window.scrollY || document.documentElement.scrollTop;
        $header.style.transform = `translateY(-${scrollY > notibar.clientHeight ? notibar.clientHeight : scrollY}px)`;
      });

      window.addEventListener('scroll', (e) => {
        if (!$header) return;
        let scrollY = window.scrollY || document.documentElement.scrollTop;
        $header.style.transform = `translateY(-${scrollY > notibar.clientHeight ? notibar.clientHeight : scrollY}px)`;
      });
    }

    window.addEventListener('resize', (e) => {
      this.notibarPadding();
    });
  };

  notibarPadding = () => {
    const $header = document.getElementsByTagName('header')[0];
    const $main = Array.from(document.getElementsByTagName('main'))[1];
    let notibar = document.getElementsByClassName('NotificationBar')[0];
    $header.style.transform = `translateY(0px)`;
    $header.style.zIndex = `10`;

    if (this.state.hasNotification) {
      setTimeout(() => {
        $main.style.paddingTop = `${notibar.clientHeight}px`;
      }, 500);
    }
  };

  getTopBanner = () => {
    if (this.state.hasNotification) {
      callApiUtil.callCrossApiGet(process.env.REACT_APP_APP_BANNER_URL, false).then((data) => {
        if (data != null && data.success && data.result != null && data.result.length != 0) {
          this.setState({
            data: data,
            offset: data.offset,
            showAdArea: true,
          });
        }
      });
    }
  };

  render() {
    let data = this.state.data;
    let offset = null;
    if (data != null) {
      offset = this.state.offset;
    }
    return this.state.showAdArea ? (
      <NotificationBar
        onLoad={(e) => {
          this.notibarLoad();
        }}
        onClose={(e) => {
          this.notibarPadding();
        }}
        className='app-download'
        sound={false}
        image={{
          appIcon: data.result[offset].image.appIcon,
          md: data.result[offset].image.md,
          sm: data.result[offset].image.sm,
          alt: data.result[offset].image.alt,
        }}
        appText={data.result[offset].appText}
        count={data.result[offset].count}
        activityName={data.result[offset].activityName}
        link={data.result[offset].link}
        type={data.result[offset].type}
        title={data.result[offset].title}
        click={data.result[offset].click}
        second={data.result[offset].second}
        target={data.result[offset].target}
        activityDsc={data.result[offset].activityDsc}
        appDesc={data.result[offset].appDesc}
        btn={data.result[offset].btn}
      />
    ) : null;
  }
}

export default TopBanner;
